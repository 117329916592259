import React, { useState } from "react"
import Img from "./img"

type VideoProps = {
  url: string
  image?: string
  onPlay?: () => void
}

export default function Video(props: VideoProps) {
  // Based on Tailwind UI component: https://tailwindui.com/components/marketing/sections/heroes#component-351873ecf5c4a3f4708d4513ea7738f6

  const [showVideo, setShowVideo] = useState(false)

  function onPlay() {
    setShowVideo(true)
    props.onPlay?.()
  }

  if (!showVideo) {
    return (
      <div className="relative mx-auto w-full rounded-lg shadow-lg max-w-full">
        <button
          onClick={onPlay}
          type="button"
          className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {props.image && (
            <Img
              src={props.image}
              className="w-full h-full"
              alt={props.url}
              cover={true}
            />
          )}
          <div
            className="absolute inset-0 w-full h-full flex items-center justify-center"
            aria-hidden="true"
          >
            <svg
              className="h-20 w-20 text-secondary"
              fill="currentColor"
              viewBox="0 0 84 84"
            >
              <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
              <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
            </svg>
          </div>
        </button>
      </div>
    )
  }
  return (
    <iframe
      src={`${props.url}?autoplay=1`}
      className="max-w-full max-h-full rounded-md"
      width="640"
      height="360"
      allow="autoplay; fullscreen"
      allowFullScreen={true}
    />
  )
}
