import React from "react"
import { Videos, Profiles } from "../../data/assets"
import { Routes } from "../../data/routes"
import Card from "../../components/card"
import { ClosingCTA } from "../../components/CTA"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HowItWorks from "../../components/HowItWorks"
import PageHeader from "../../components/PageHeaderBlueH1"
import Testimonial from "../../components/Testimonial"
import ValuesListSimple from "../../components/ValuesListSimple"

import {
  LightningBoltIcon,
  AcademicCapIcon,
  TrendingUpIcon,
  SparklesIcon,
  BookOpenIcon,
  VariableIcon,
} from "@heroicons/react/outline"

const Content = {
  pageName: "For Teachers - Online Math Tutoring for School Students",
  subheader: "Online Math Tutoring for School Students",
  header: `Yup is your virtual, on-demand, Math Teaching Assistant`,
  description: `Yup’s Math Learning Support System extends the reach of teachers by
    giving each of your students 24/7 access to personalized math tutoring, and giving
    you full control over how those tutors complement your instruction as well as the
    resulting learning data. Just like a Teaching Assistant, Yup aims to make your life
    easier, lighten your workload and ultimately reduce burnout.`,
  video: Videos.yupForTeachers,
  headerCTALink: Routes.schools,
  headerCTAText: "Schedule Demo",

  // Testimonial section
  testimonial: `Our school started using Yup this year and we were really impressed.
    Students who don't typically have access to tutoring feel empowered to learn on
    their own with Yup! Any school that is looking for a personalized approach to
    math instruction should be looking at Yup.`,
  testimonialProfile: Profiles.drew,
  testimonialName: "Drew Karimlou",
  testimonialJob1: "Instructional Coach",
  testimonialJob2: "Synergy Academies",

  // How it works section
  howItWorksHeader: `What Is Online Math Tutoring for School Students?`,
  howItWorksText: `Yup is an on-demand, online tutoring service that is available 24
     hours a day, seven days a week. Yup's unlimited, personalized math tutoring
     helps your students any time, anywhere. Whether during class, study hall, or at
     home, Yup makes personalized tutoring simple and a natural extension of your classroom.`,
  howItWorksAction: "Learn More",
  howItWorksLink: Routes.features,

  // Help section
  helpStudentHeader: "How Online Math Tutoring Services Help Students",
  helpStudentDescription: `In 2020, the pandemic was a curveball that threw the state of
    education for a loop. Schools were forced to abruptly close and switch to remote
    learning.  The unexpected time away from classrooms created widespread gaps between
    student grade levels and their educational performance.
    Dozens of studies have demonstrated that tutoring works to
    accelerate student achievement across grade levels.`,
  helpStudentFacts: [
    {
      label: "Elementary and Middle School Students",
      icon: BookOpenIcon,
      description: `A <a href="https://studentsupportaccelerator.com/research/to-date" target="_blank" class="text-secondary">recent meta-analysis</a>
        demonstrated that tutoring was the most effective among more than a
        dozen other academic interventions for K-8 students from low
        socioeconomic backgrounds.

        Tutoring support systems can help school students increase their
        test scores and gain the equivalent of
        <a href="https://studentsupportaccelerator.com/research/to-date" target="_blank" class="text-secondary">3-15 months of extra classroom instructional time</a>.`,
      points: [],
    },
    {
      label: "High School Students",
      icon: VariableIcon,
      description: `In a <a href="https://www.povertyactionlab.org/sites/default/files/publication/Evidence-Review_The-Transformative-Potential-of-Tutoring.pdf" target="_blank" class="text-secondary">2017 study</a>,
        tutoring services for 9th and 10th graders:`,
      points: [
        `Raised students' national percentile ranking on math exams by >20%`,
        `Increased student GPAs by 0.58 out of a 4.0 grade point scale`,
        `Caused failure rates in math to fall by >50%`,
      ],
    },
    {
      label: "College Students",
      icon: AcademicCapIcon,
      description: `College-level math classes are frequently
        <a href="https://www.renaissance.com/2018/03/22/blog-math-milestones-critical-role-math-achievement-student-success/" target="_blank" class="text-secondary">gatekeepers</a>
        to other courses and eventual graduation.

        Online math tutoring can help college students better understand the
        material and succeed in their coursework, helping them advance their
        postsecondary goals.`,
      points: [],
    },
  ],
  helpTeacherHeader: "How Online Math Tutoring Services Help Teachers",
  helpTeacherText: `Yup's online tutoring service accelerates student learning
    while expanding teacher capacity.

    Teachers are dedicated to their work and their students, yet frequently they are
    <a href="${Routes.blog}/supporting-overwhelmed-teachers/" target="_blank" class="text-secondary">overwhelmed, under-resourced</a>,
    and can suffer from
    <a href="${Routes.blog}/unpacking-teacher-burnout/" target="_blank" class="text-secondary">burnout</a>.
    Yup is here to be your math teacher multiplier. Yup's unlimited, personalized,
    on-demand tutoring is like having a Teaching Assistant to support you and your
    students anytime, anywhere.`,
  helpTeacherFacts: [
    {
      feature: "Extend your reach",
      icon: LightningBoltIcon,
      description: `Yup's 24/7 chat-based platform is there for all of your students.
        Yup tutoring can be
        <a href="${Routes.highDosageTutoring}" target="_blank" class="text-secondary">integrated into the school day</a>,
        helping you make sure all students get the help they need, when they need it.`,
    },
    {
      feature: "Focus on what you need",
      icon: AcademicCapIcon,
      description: `Yup’s unlimited personalized tutoring gives peace of mind knowing
        your students have the support they need, and lets you focus your time and
        effort where it matters most.`,
    },
    {
      feature: "Use data to find what works",
      icon: TrendingUpIcon,
      description: `Yup's Dashboard give you real-time access to student usage metrics,
        session transcripts, and summaries that recap student learning. Our School
        Success Team reviews all sessions and shares data-driven insights every week.`,
    },
  ],

  // Quote
  yupQuote: `You deserve support that's easy to use and
    aligned to what you're doing`,
  yupQuoteTeam: "Yup Team",

  // Mid page CTA
  midPageCTA: "Want to know more?",
  midPageCTA2: "Book a Yup demo",
  midPageCTAActions: [
    {
      link: Routes.schools,
      text: "Book a demo",
    },
  ],

  // Values section
  values: [
    {
      icon: SparklesIcon,
      header: "Tutoring that ensures deep understanding",
      description: `Yup's evidence-based Teaching Framework draws heavily from the
        The National Council of Teachers of Mathematics' (NCTM) Teaching Practices.
        Our Framework encourages tutors to use a question-based approach to uncover
        misconceptions and develop conceptual understanding while making sure students
        feel heard and supported.`,
      action: "How we teach",
      link: Routes.learningFundamentals,
      image: "icon-for-schools.svg",
    },
    {
      icon: AcademicCapIcon,
      header: "Expert tutors who meet students where they're at",
      description: `Our rigorous application process ensures tutors have strong math
        fundamentals and effective written communication skills, so they can assist
        any student across all grade levels. Less than 5% of applicants are accepted.
        Tutors' sessions are hand-graded by a Tutor Quality Manager to ensure adherence
        to Yup's Teaching Framework.`,
      action: "Meet our tutors",
      link: Routes.ourTutors,
      image: "icon-quality.svg",
    },
  ],

  // Closing CTA
  closingCTA: "Learn what it means to bring Yup to your classroom",
  closingCTA2: "",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule demo",
    },
  ],
}

function HelpTeacherSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-cdca7eda27bdaceb45026dca4c17efe7
  return (
    <Card>
      <div className="text-center">
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {Content.helpTeacherHeader}
        </p>
        <p
          className="mx-auto mt-5 max-w-5xl text-xl text-gray-500"
          dangerouslySetInnerHTML={{ __html: Content.helpTeacherText }}
        />
      </div>
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {Content.helpTeacherFacts.map(fact => (
              <div key={fact.feature} className="pt-6">
                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-blue-500 rounded-md shadow-lg">
                        <fact.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {fact.feature}
                    </h3>
                    <p
                      className="mt-5 text-base text-gray-500"
                      dangerouslySetInnerHTML={{ __html: fact.description }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}

function HowItWorksSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-25ce2a81d17545994655ccc4062651a4
  return (
    <Card>
      <div className="flex flex-col lg:flex-row text-center lg:text-left items-center">
        <div className="px-4">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {Content.howItWorksHeader}
          </h2>
          <p className="mt-5 text-xl text-gray-500">{Content.howItWorksText}</p>
        </div>
      </div>
      <HowItWorks showDescription={true} />
    </Card>
  )
}

function HelpStudentSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-cdca7eda27bdaceb45026dca4c17efe7
  return (
    <Card>
      <div className="text-center">
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {Content.helpStudentHeader}
        </p>
        <p
          className="mx-auto mt-5 max-w-5xl text-xl text-gray-500"
          dangerouslySetInnerHTML={{ __html: Content.helpStudentDescription }}
        />
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {Content.helpStudentFacts.map(fact => (
              <div key={fact.label} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8 h-full">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-secondary p-3 shadow-lg">
                        <fact.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                      {fact.label}
                    </h3>
                    {fact.description?.split(/\n\n/g).map((description, i) => (
                      <p
                        key={description}
                        className="mt-5 text-left text-base text-gray-500"
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    ))}
                    <ul className="list-disc text-left px-4 mt-4 text-gray-500">
                      {fact.points.map(point => (
                        <li
                          key={point}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  )
}

function Quote() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-fd7b8bd425f42f6504b22e1ecc6b43c9
  return (
    <div className="bg-secondary mb-8 p-8">
      <div className="mx-auto max-w-7xl">
        <blockquote className="mt-10">
          <div className="max-w-4xl mx-auto text-center text-3xl sm:text-4xl leading-9 font-medium text-white">
            <p>&ldquo;{Content.yupQuote}&rdquo;</p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-lg sm:text-xl font-medium text-white">
                  ~ {Content.yupQuoteTeam}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  )
}

const Educators = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.header}
        route={Routes.teachers}
      />
      <PageHeader
        smallTitle={Content.subheader}
        mainTitle={Content.header}
        description={Content.description}
        video={Content.video}
        ctaLink={Content.headerCTALink}
        ctaText={Content.headerCTAText}
      />
      <Testimonial
        image={Content.testimonialProfile}
        testimonial={Content.testimonial}
        name={Content.testimonialName}
        jobs={[Content.testimonialJob1, Content.testimonialJob2]}
      />
      <HelpStudentSection />
      <HowItWorksSection />
      <HelpTeacherSection />
      <Quote />
      <ValuesListSimple values={Content.values} />
      <ClosingCTA
        title={Content.closingCTA}
        title2={Content.closingCTA2}
        actions={Content.closingCTAActions}
      />
    </Layout>
  )
}

export default Educators
