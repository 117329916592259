import React from "react"
import { PrimaryButton, SecondaryButton } from "../components/button"
import Card from "../components/card"
import Img from "../components/img"

type Value = {
  icon: React.ElementType
  header: string
  description: string
  action: string
  link: string
  image: string
}

type ValuesListSimpleProps = {
  values: Array<Value>
}

export default function ValuesListSimple(props: ValuesListSimpleProps) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-fea10362c98dcf74d601fab911a1aee3

  return (
    <Card>
      {props.values.map((section, index) => (
        <div
          className={`flex flex-col ${
            index % 2 ? "md:flex-row" : "md:flex-row-reverse"
          } max-w-7xl mx-auto items-center`}
          key={`teaching-section-${index}`}
        >
          <div className="w-full p-4 flex-grow md:w-1/2">
            {section.video ? (
              <Video url={section.video.url} image={section.video.cover} />
            ) : (
              <Img
                src={section.image}
                alt={section.imageAltText}
                className="w-full h-full max-w-sm object-fit mx-auto"
              />
            )}
          </div>
          <div className="text-center md:text-left w-full py-12 md:w-1/2">
            <div className="md:ml-auto md:px-10">
              <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
                {section.header}
              </p>
              {section.description.split(/\n\n/g).map((description, i) => (
                <p
                  className="mt-3 text-lg text-gray-500"
                  key={`teaching-section-${index}-${i}`}
                >
                  {description}
                </p>
              ))}
              <div className="mt-8">
                <SecondaryButton href={section.link} text={section.action} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}
